@import "./variables.scss";
.bg-heading {
    background: url("../icons/vectorback.jpeg") top center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 79vw;
    .heading {
        // margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        // padding: max(1.5vw, 3vh);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .title {
            font-size: max(19vw, 17vh);
            font-family: $Bold;
            font-weight: bold;
            width: 100%;
            margin-top: 100px;
            text-align: center;
        }
        .description {
            text-align: center;
            // margin-top: 1em;
            font-weight: 300;
            letter-spacing: 3px;
            border-radius: $br2;
            margin-bottom: 300px;
        }
    }
    @media (max-width: 750px) {
        height: 100vh;
        background: url("../../public/land/vectorlandbg.png") top center;
        background-size: cover;
        .heading {
            height: 100vh;
            .heading-wrapper{
                display: none;
            }
        }
    }
}