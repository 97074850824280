@import "./variables.scss";
.container {
    .tg-wrapper {
        // padding: 1.5vw;
        margin-bottom: 1em;
        margin-bottom: 1em;
        width: 100%;
        // border-radius: $br
        display: flex;
        flex-direction: row;
        // background: rgba(255, 255, 255, 0.706);
        background: url("../icons/tg.jpg");
        // border-radius: $br1 0 0 $br1;
        background-position: 0%;
        transition: none;
        border: 1px solid rgb(0, 0, 0);
        border-radius: $br1;
        margin: 1.5vw;
        height: 55vw;
        background-size: 100% auto;
        @media(max-width: 750px) {
            height: 125vw;
            background: url("../icons/tg-mob.jpg");
            background-size: 100% auto;
        }
    }
    .tg-wrapper:hover {
        cursor: pointer;
        border: 1px solid rgb(97, 97, 97);
    }
}