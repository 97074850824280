@import "../../styles/variables.scss";

#token {
  padding-top: 3vh;
  padding-bottom: 3vh;
  //color: $color6;
  .token-wrapper {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
    margin: 1.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: $br1;
    background: $color3;
    transition: none;

    .token-title {
      margin-bottom: 1em;
      margin-top: 0.5em;
      font-size: max(2.5vw, 5vh);

      h1 {
        margin: 0
      }

      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .token-button {
      width: 90%;
      cursor: pointer;
      margin: 2em;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .button {
        flex: 1;
        margin: 0 1em;
        background: $color6;
        color: $color2;
      }

      #channel {
        background: $color2;
        color: $color6;
      }

      .button:hover, #channel:hover {
        background: $color1;
        color: $color2;
        box-shadow: $color6 0px 0px 15px;
      }

    }

    .community-courses {
      width: 100%;
      transition: none;

      .row {
        transition: none;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        // margin-bottom: 1.5vw;
        .community-wrapper {
          transition: none;
          width: 45vw;
          margin-bottom: 1.5vw;
        }
      }

      @media (max-width: 750px) {
        flex-direction: column;
        align-items: center;
        .course-wrapper {
          width: 90vw;

          .course {
            height: 41.6vw;
          }
        }
      }
    }

    .rules {
      .token-title {
        font-size: 10vh;
        margin: 0.5em 0;
      }

      .token-section {
        .token-title {
          margin: 0.5em 0;
          font-size: 4vh;
        }

        .token-subtitle {
          font-size: 3vh;
          margin: 1em 0;
        }

        .rule-description {
          margin: 1em;

          tr {
            margin-left: 1em;
          }
        }
      }
    }
  }

  .token-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    p {
      margin: 0.5em;
      font-size: 2vh;
      line-height: 1.5em;
      text-align: justify;
      max-width: min(45em, 80vw);
      font-weight: 300;
      letter-spacing: 3px;
      border-radius: $br2;
      text-indent: 25px;

    }

    margin-bottom: 1em;
    margin-top: 1em;
    font-weight: 300;
    letter-spacing: 3px;
    border-radius: $br2;
  }

  .interviewimg {
    width: 90vw;
    border-radius: $br2;
    margin-bottom: 1em;
  }

  .g-image {
    width: 50vh;
    margin: 1em;
    border-radius: $br2;
  }
}