@import "../styles/variables.scss";
#network {
    background-color: black;
    .networkdesc-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 4em;
        .button-wrapper {
            margin: 0.5em;
            margin-top: 2em;
            width: calc(100% - 1em);
            p {
                font-size: max(max(2vw, 2vh), 30px);
                margin-top: 4px;
            }
        }
        .arrow {
            margin-bottom: 1em;
        }
        .networkdesc-title {
            //  margin-bottom: 1em;
            font-size: max(max(2vw, 2vh), 30px);
            text-align: center;
        }
        .networkdesc-block {
            max-width: 90%;
            width: 60em;
            background: #00422c;
            padding: 1em;
            border-radius: $br1;
            .description {
                letter-spacing: 0;
            }
            .networkdesc-block-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                .networkdesc-image {
                    width: 50em;
                    max-width: 100%;
                    border-radius: $br2;
                    margin-bottom: 1em;
                }
                .networkdesc-description {
                    text-align: center;
                    max-width: 90%;
                    font-size: max(max(1.7vw, 2.5vh), 25px);
                }
                @media (min-width: 750px) {
                    flex-direction: row;
                    .networkdesc-image {
                        margin-bottom: 0em;
                        margin-right: 1em;
                        width: 40em;
                        max-width: calc(50%);
                    }
                    .networkdesc-description {
                        text-align: left;
                    }
                }
                //  margin-bottom: 1em;
            }
        }
    }
}