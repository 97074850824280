    @import "./variables.scss";
    .academy-wrapper {
        margin-top: 1em;
        margin-bottom: 1em;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        width: 100%;
        margin: 1.5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: $br1;
        background: $color3;
        transition: none;
        .academy-title {
            margin-bottom: 1em;
            margin-top: 0.5em;
            font-size: max(1.5vw, 3vh);
        }
        .academy-courses {
            cursor: pointer;
            width: 100%;
            transition: none;
            .row {
                transition: none;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                // margin-bottom: 1.5vw;
                .course-wrapper {
                    transition: none;
                    width: 45vw;
                    margin-bottom: 1.5vw;
                    .course {
                        transition: none;
                        height: 20.8vw;
                        background: url("../icons/victor-laptop.jpg");
                        background-size: auto 100%;
                        border-radius: $br2;
                        background-position: 0%;
                    }
                    .img-1 {
                        background: url("../icons/hustlers.jpg");
                        background-size: auto 100%;
                        background-position: 0%;
                    }
                    .img-2 {
                        background: url("../icons/dm.jpg");
                        background-size: auto 100%;
                        background-position: 0%;
                    }
                    .img-3 {
                        background: url("../icons/crypta.jpg");
                        background-size: auto 100%;
                        background-position: 0%;
                    }
                    .img-4 {
                        background: url("../icons/tovar.jpg");
                        background-size: auto 100%;
                        background-position: 0%;
                    }
                    .img-5 {
                        background: url("../icons/fit.jpg");
                        background-size: auto 100%;
                        background-position: 0%;
                    }
                }
                @media (max-width: 750px) {
                    flex-direction: column;
                    align-items: center;
                    .course-wrapper {
                        width: 90vw;
                        .course {
                            height: 41.6vw;
                        }
                    }
                }
            }
        }
        .academy-description {
            display: flex;
            flex-direction: column;
            p {
                text-align: center;
                max-width: min(45em, 80vw);
                font-weight: 300;
                letter-spacing: 3px;
                border-radius: $br2;
            }
            margin-bottom: 1em;
            margin-top: 1em;
            font-weight: 300;
            letter-spacing: 3px;
            border-radius: $br2;
        }
        .interviewimg {
            width: 93vw;
            border-radius: $br2;
            margin-bottom: 1em;
        }
    }