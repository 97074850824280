@import "./variables.scss";
.bg-heading-net {
    background: url("../icons/vnback.jpg") top center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 79vw;
    width: 100%;
    .heading-net {
        width: 100%;
        .heading-net-wrapper {
            // margin-top: 100px;
            width: 100%;
            height: 100%;
            // padding: max(1.5vw, 3vh);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .title {
                font-size: max(10vw, 10vh);
                font-family: $Bold;
                font-weight: bold;
                width: 100%;
                margin-top: 100px;
                text-align: center;
            }
            .description {
                text-align: center;
                // margin-top: 1em;
                font-weight: 500;
                font-size: max(3vw, 3vh);
                font-weight: bold;
                // height: 1em;
                letter-spacing: 3px;
                border-radius: $br2;
                margin-bottom: 1em;
                display: flex;
                .green {
                    margin-left: 0.3em;
                }
            }
        }
    }
    @media (max-width: 750px) {
        height: 100vh;
        // background: url("../icons/car.jpg") top center;
        background-size: cover;
        .heading-net {
            height: 100vh;
            .heading-net-wrapper {
                .title {
                    margin-top: 200px;
                    font-size: max(10vw, 10vh);
                }
            }
        }
    }
}