@import "./variables.scss";
.container {
    .network-wrapper {
        // padding: 1.5vw;
        margin-top: 1em;
        margin-bottom: 1em;
        margin: 1.5vw;
        // padding-bottom: 1.5em;
        width: 100%;
        border-radius: $br1;
        display: flex;
        border: 1px solid rgb(0, 0, 0);
        flex-direction: row;
        // background: rgba(255, 255, 255, 0.706);
        background: url("../icons/network.png");
        background-color: #00af7200;
        // border-radius: $br1 0 0 $br1;
        background-position: 0%;
        transition: none;
        height: 83vw;
        background-size: 100% auto;
        @media(max-width: 750px) {
            // height: 120vw;
            // background: url("../icons/network-mobile.jpg");
            background-size: 100% top;
        }
    }
    .network-wrapper:hover {
        cursor: pointer;
        border: 1px solid rgb(97, 97, 97);
    }
}