@import "../../styles/variables.scss";

.productonland {
  width: 100%;
  //border-radius: 30px;
  //overflow: hidden;
  user-select: none;
  //height: min(75vw, 30em);

  .productonland-wrapper {
    width: 100%;
    //padding: 10px;
    display: flex;
    flex-direction: column;

    .productonland-cover {
      display: flex;
      width: 100%;
      //overflow: hidden;

      .productonland-img {

        width: 150vw;
        z-index: -3;
        position: fixed;
        left: -25vw;
        transition: none;
      }

      .productonland-title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        user-select: none;
        z-index: 2;
        //position: relative;
        width: 100%;
        height: 100%;
        margin-top: 30vh;

        top: 30vh;
        h3{
            color: rgb(222, 222, 222);
          font-weight: 300;
          margin-bottom: 0.3em;
        }

        .productonland-calltoaction {
          width: 100%;
          padding: 1em;
          .productonland-button {
            cursor: pointer;
            user-select: none;
            background: rgba(222, 222, 222, 0.1);
            border: 2px solid $color1;
            color: $color1;
            padding: 10px 30px;
            font-weight: 900;
            width: 100%;
            padding-top: 0.7em;
            text-align: center;
            font-size: 20px;
            border-radius: $br2;
            //border: 1px solid $color3;
            //box-shadow: 4px 4px 0px 0px rgba(34, 60, 80, 0.2);
            //text-shadow: rgba(0, 0, 0, 0.24) 1px 1px 5px;
          }
        }
      }
    }


  }
}