@import "../../styles/variables.scss";
#community {
    padding-top: 100px;
    .community-wrapper {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        width: 100%;
        margin: 1.5vw 0vw 1.5vw 0vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: $br1;
        background: $color3;
        transition: none;
        .community-title {
            // margin-bottom: 1em;
            margin-top: 0.5em;
            font-size: max(3vw, 3vh);
            align-items: center;
            display: flex;
            flex-direction: column;
        }
        .chat-button {
            width: 93vw;
            margin: 1em 0;
            .button {
                padding-top: 15px;
                width: 100%;
            }
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: max(max(2vw, 2vh), 30px);
        }
        .community-courses {
            width: 100%;
            transition: none;
            .row {
                transition: none;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                // margin-bottom: 1.5vw;
                .community-wrapper {
                    transition: none;
                    width: 45vw;
                    margin-bottom: 1.5vw;
                }
            }
            @media (max-width: 750px) {
                flex-direction: column;
                align-items: center;
                .course-wrapper {
                    width: 90vw;
                    .course {
                        height: 41.6vw;
                    }
                }
            }
        }
        .rules {
            .rule-section {
                .rule-title {
                    margin: 1em;
                }
                .rule-subtitle {
                    margin: 1em 0;
                }
                .rule-description {
                    margin: 1em;
                    tr {
                        margin-left: 1em;
                    }
                }
            }
        }
    }
    .community-description {
        display: flex;
        flex-direction: column;
        p {
            text-align: center;
            max-width: min(45em, 80vw);
            font-weight: 300;
            letter-spacing: 3px;
            border-radius: $br2;
        }
        margin-bottom: 1em;
        margin-top: 1em;
        font-weight: 300;
        letter-spacing: 3px;
        border-radius: $br2;
    }
    .interviewimg {
        width: 93vw;
        border-radius: $br2;
        margin-bottom: 1em;
    }
    .join-text {
        font-size: max(max(2vw, 2vh), 50px);
    }
}