@import "./variables.scss";
.container {
    .carousel-wrapper {
        padding: 1.5vw;
        margin-top: 1em;
        margin-bottom: 1em;
        width: 100%;
        // border-radius: $br1;
        display: flex;
        flex-direction: row;
        // background: rgba(255, 255, 255, 0.706);
        background: url("../icons/desktop-ac.jpg");
        // border-radius: $br1 0 0 $br1;
        background-position: 0%;
        margin: 1.5vw;
        transition: none;
        border: 1px solid rgb(0, 0, 0);
        border-radius: $br1;
        height: 45vw;
        background-size: 100% auto;
        @media(max-width: 750px) {
            // height: 120vw;
            // background: url("../icons/mobile-ac.jpg");
            background-size: 100% auto;
        }
    }
    .carousel-wrapper:hover {
        cursor: pointer;
        border: 1px solid rgb(97, 97, 97);
    }
}