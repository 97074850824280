@import "./variables.scss";

.container {
  .free-wrapper {
     padding: 1.5vw;
    //margin: 1.5vw;

    padding-top: 1.5em;
    padding-bottom: 1.5em;

    // padding-bottom: 1.5em;
    width: 100%;
    flex-direction: row;
    background: $color2;
    // background: rgba(255, 255, 255, 0.706);
    // border-radius: $br1 0 0 $br1;

    .free-title {
      width: 100%;
      font-size: 10vw;
      font-weight: 700;
      text-align: center;
      color: $color1;
      margin-bottom: 0.2em;
    }

    .img {
      background-position: 100% top;
      transition: none;
      //height: 49.4vw;
      background-size: 100% auto;
      //background: url("../../public/land/roadmapers.png");
      background-color: #00af7200;
      border-radius: $br1;
      display: flex;
      border: 1px solid rgb(0, 0, 0);
      width: 100%;

    }

    @media(max-width: 750px) {
      // height: 120vw;
      // background: url("../icons/network-mobile.jpg");
      .img {
        background-size: 100%;
      }
    }
  }

  .img:hover {
    cursor: pointer;
    border: 1px solid rgb(97, 97, 97);
  }
}