@import "./variables.scss";
* {
    box-sizing: border-box;
    margin: 0;
    transition: 0.1s;
}

p {
    font-weight: 300;
    letter-spacing: 3px;
    border-radius: $br2;
}

h1 {
    font-weight: 1000;
    letter-spacing: 1px;
}

h2 {
    font-weight: 1000;
    letter-spacing: 1px;
}

h3 {
    font-weight: 1000;
    letter-spacing: 1px;
}

.scroll-videos {
    margin-left: -1em;
    border-radius: $br2;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
}

html {
    scroll-behavior: smooth;
}

body {
    width: 100%;
    background: $color2;
    color: $color1;
    font-family: $regular;
}

#root {
    height: 100%;
}

.container {
    width: 100%;
    // max-width: 1500px;
    display: flex;
}

@media (max-width:650px) {}

.button {
    font-family: $Bold;
    position: relative;
    color: $color2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: $color1;
    border-radius: $br2;
    padding: 10px 20px;
    p {
        padding-top: 1px;
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 0px;
        font-size: 15px;
        // padding-left: 3px;
    }
}

.contour {
    cursor: pointer;
    background: $color2;
    color: $color1;
    border: 1px solid $color1;
}

.primary {
    cursor: pointer;
    background: $color3;
    color: $color1;
    padding: 10px 35px;
}

.main-container {
    width: calc(100% - 4vw);
    margin: 2vw;
}

.shadow {
    box-shadow: 0px 2px 24px 0px rgba(0, 0, 0);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0);
}

.button-wrapper {
    cursor: pointer;
}
.arrow-bg{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: $color2;
}