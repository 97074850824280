@font-face {
    font-family: "BebasNeueBold";
    src: url("../font/BebasNeueBold.ttf");
}

@font-face {
    font-family: "Helvetica";
    src: url("../font/BebasNeueRegular.ttf");
}

@font-face {
    font-family: "Regular";
    src: url("../font/BebasNeueRegular.ttf");
}

$Bold: "BebasNeueBold",
sans-serif;
$helvetica: "Helvetica",
sans-serif;
$regular: "Regular"; // spasing
$spasing-md: 16px;
$spasing-lg: 32px;
$lg: 36px;
//border radius
$borderRadius: 11px;
$br1: 12px;
$br2: 14px;
//header height
$headerHeight: 100px;
//colors
$color2: #0d0d0d;
$color3: #121212;
$color1: #fff;
$color4:#0057FF;
$color5:#6b2a2a;
$color6: #00FF0F;
@mixin breakpoint($points) {
    @if $points==md {
        // 768 px
        @media (min-width: 48em) {
            @content;
        }
    }
}

h1 {
    font-family: $Bold;
}

// h2,
// h3,
// a {
//     letter-spacing: 2px;
// }