    @import "../../../styles/variables.scss";
    .join-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        .heading {
            margin-top: 0em;
            width: 30em;
            max-width: 80vw;
        }
        .heading-join {
            margin-top: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            padding: max(1.5vw, 3vh);
            font-size: max(19vw, 19vh);
            font-family: $Bold;
            font-weight: bold;
            text-align: center;
            .description {
                text-align: center;
                margin-top: 1em;
                font-weight: 300;
                letter-spacing: 3px;
                border-radius: $br2;
            }
        }
        .signup {
            margin-bottom: 10vh;
            width: 30em;
            max-width: 80vw;
            background: $color3;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: $br1;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            .inputs {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 2em;
                min-height: 20vh;
                width: 100%;
                .input {
                    background: $color2;
                    color: $color1;
                    margin: 0.5em 0;
                    font-size: large;
                    padding: 0.5em 1em;
                    width: 80%;
                    border-radius: $br2;
                    border: none;
                    text-align: center;
                    font-family: $regular;
                }
            }
            .button {
                width: 80%;
            }
        }
    }