@import "../../styles/variables.scss";
.productsonland {
  background: linear-gradient(180deg, $color2 10%, #12121200 50%, #12121200 75%);
  width: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
  //height: 35vh;
  .productsonland-wrapper {
    margin: 1em 0em;
    display: flex;
    flex-direction: column;
    width: 100%;
    .productsonland-title {
        width: 100%;
        font-size: 10vw;
        font-weight: 700;
        text-align: center;
    }

    .productsonland-list {


    }
  }
}