@import "./variables.scss";
.navbar-wrapper {
    z-index: 100;
    position: fixed;
    transition-duration: 1s;
    width: 100%;
    display: flex;
    padding: 1.5vw;
    flex-direction: row;
    justify-content: space-between;
    .left-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        h1 {
            font-size: 50px;
        }
        img {
            margin-top: -5px;
            margin-right: 1.5em;
        }
    }
    .navbar-logo {
        height: $lg;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .navbar-login {}
    .navbar-tap-to {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        .button-wrapper {
            margin: 0em 1em;
        }
    }
    .navbar-login {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .button-wrapper {
            margin: 0em 1em;
        }
    }
    @media (max-width: 750px) {
        flex-direction: column;
        .navbar-tap-to {
            display: none;
        }
        .navbar-login {
            width: 100%;
            .button-wrapper {
                flex: 1;
            }
        }
        .navbar-logo {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 1em;
        }
    }
}

.solid {
    background: black;
}