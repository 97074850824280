@import "../styles/variables.scss";
.footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1.5vw;
    margin-bottom: 1.5vw;
    h3 {
        font-size: max(1.5vw, 3vh);
        // font-family: $Bold;
    }
    a {
        text-decoration: none;
        color: $color1;
    }
    .footer-links {
        display: flex;
        flex-direction: row;
        text-align: center;
        font-weight: bold;
        justify-content: space-evenly;
        a {
            font-family: $regular;
            letter-spacing: 2px;
            margin: 0.3em;
            font-size: small;
            font-weight: 300;
            padding-top: 1px;
            text-transform: uppercase;
            font-weight: 300;
            letter-spacing: 3px;
            font-size: 15px;
            margin: 1.5em;
        }
        a:hover {
            color: rgb(118, 125, 248);
        }
        margin: 1em;
        @media (max-width: 750px) {
            flex-direction: column;
        }
    }
    .rights {
        color: rgb(104, 104, 104);
        font-size: small;
        text-align: center;
    }
}